.customOverlay {
  background: rgba(36, 123, 160, 0.2);
}
.customModal {
  background: #edfff3;
  max-width: 500px;
  width: 80%;
  max-height: 80%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.overflow-x-auto::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-x-auto {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#root {
  max-width: 500px;
  margin: 0 auto;
}