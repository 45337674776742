.custom-checkbox {
    @apply appearance-none h-4 w-4 border border-[#FD7347] rounded-md checked:bg-[#FD7347]  ;
}

.custom-checkbox:checked {
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 5.207a1 1 0 00-1.414-1.414l-4 4-2-2a1 1 0 10-1.414 1.414l2.5 2.5a1 1 0 001.414 0l5-5z"/></svg>');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
